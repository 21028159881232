import { axios } from '../common/request'

//猪崽列表
export function getList(data) {
  return axios.request({
    url: '/api/pig/pig_list',
    method: 'GET',
    params: {
      ...data,
    },
  })
}
//领养猪崽详情
export function getDetail(data) {
  return axios.request({
    url: '/api/pig/info',
    method: 'GET',
    params: {
      ...data,
    },
  })
}
//领养
export function adoption(data) {
  return axios.request({
    url: '/api/pig/adopt',
    method: 'POST',
    params: {
      ...data,
    },
  })
}
//协议
export function getXieyi(data) {
  return axios.request({
    url: '/api/Publics/get_xieyi',
    method: 'POST',
    params: {
      ...data,
    },
  })
}

//预约领养
export function reservation(data) {
  return axios.request({
    url: '/api/pig/reservation',
    method: 'POST',
    params: {
      ...data,
    },
  })
}

//预约金额
export function getmoney(data) {
  return axios.request({
    url: '/api/pig/deposit',
    method: 'GET',
    params: {
      ...data,
    },
  })
}

//取名
export function named(data) {
  return axios.request({
    url: '/api/pig/named',
    method: 'POST',
    params: {
      ...data,
    },
  })
}

//猪崽详情
export function pigInfo(data) {
  return axios.request({
    url: '/api/feed/pig_info',
    method: 'POST',
    params: {
      ...data,
    },
  })
}

//喂食食物列表 type:1食物   2 清洁
export function feedFood(data) {
  return axios.request({
    url: '/api/feed/feed_food',
    method: 'POST',
    params: {
      ...data,
    },
  })
}

//喂食套餐列表
export function feedMeal(data) {
  return axios.request({
    url: '/api/feed/feed_meal',
    method: 'POST',
    params: {
      ...data,
    },
  })
}

//喂食
export function feed(data) {
  return axios.request({
    url: '/api/feed/feed',
    method: 'POST',
    params: {
      ...data,
    },
  })
}

//喂食
export function pigFiles(data) {
  return axios.request({
    url: '/api/pig/pig_files',
    method: 'POST',
    params: {
      ...data,
    },
  })
}

//喂养记录
export function record(data) {
  return axios.request({
    url: '/api/feed/record',
    method: 'POST',
    params: {
      ...data,
    },
  })
}

//清洗
export function wash(data) {
  return axios.request({
    url: '/api/feed/wash',
    method: 'POST',
    params: {
      ...data,
    },
  })
}

//获取萤石云token
export function getToken() {
  return axios.request({
    url: '/api/public/get_token',
    method: 'get',
  })
}

// 游乐场 猪崽档案
export function pigInfos(data) {
  return axios.request({
    url: '/api/public/pig_info',
    method: 'GET',
    params: {
      ...data,
    },
  })
}

// 游乐场 喂养记录
export function feedList(data) {
  return axios.request({
    url: '/api/public/feed_list',
    method: 'GET',
    params: {
      ...data,
    },
  })
}

// 开关
export function ranchData(data) {
  return axios.request({
    url: '/api/public/ranch',
    method: 'POST',
    params: {
      ...data,
    },
  })
}
