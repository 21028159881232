// 通用接口地址
let baseUrl
if (process.env.NODE_ENV == 'development') {
  baseUrl = 'http://api.hxnc.ikuyoo.cn/'
  // baseUrl = "https://api.hxnc.cc";
} else {
  baseUrl = 'https://api.hxnc.cc'
  // baseUrl = 'http://api.hxnc.ikuyoo.cn/'
}

// 萤石云  获取token 接口地址
let ysyURL = 'https://open.ys7.com/api/lapp/token/get'

// 萤石云  appKey
let appKey = '0df2a17245b8484aa806b9218eea591e'

//萤石云  appSecret
let appSecret = 'fe76bad1612a47185013947fde984a81'
export default { baseUrl, ysyURL, appKey, appSecret }
